(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("THREE"));
	else if(typeof define === 'function' && define.amd)
		define("MathBox", ["THREE"], factory);
	else if(typeof exports === 'object')
		exports["MathBox"] = factory(require("THREE"));
	else
		root["MathBox"] = factory(root["THREE"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__824__) {
return 